.source_v3{
    width: 11rem;
    min-height: 6rem;
    background-color: var(--v3-light-gray-clr);
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    transition: 0.5s;
    color: var(--v3-dark-clr);
}

.source_v3:hover{
    background-color: var(--v3-medium-gray-clr);
}

.source_v3__description{
    font-size: 0.7rem;
}
.source_v3__web{
    display: flex;
    gap: 0.25rem;
}

.source_v3__web img{
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
}
.source_v3__web__name{
    font-size: 0.8rem;
}


@media screen and (max-width: 768px) {
    .source_v3{
        flex: 1;
    }
}