.singlechat_v3__bot_chat{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.singlechat_v3__bot{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.singlechat_v3__sources_list{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.singlechat_v3__sub_title{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
}

.singlechat_v3__sub_title_text{
    font-weight: bold;
}

.singlechat_v3__user{
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;

}

.singlechat_v3__user_msg{
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 4px;
    color: var(--v3-primary-clr);
}

.singlechat_v3__follow_ups_list{
    padding-bottom: 2rem;
}

.singlechat_v3__chat_actions{
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    color: var(--v3-dark-clr);
}
.singlechat_v3__populate_smartbuild{
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    transition: 0.5s;
}

.singlechat_v3__populate_smartbuild:hover{
    color: var(--v3-primary-clr);
}


.singlechat_v3__other_actions{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.singlechat_v3__bot .action_icons{
    cursor: pointer;
    transition: 0.5s;
}

.singlechat_v3__bot .action_icons:hover{
    color: var(--v3-primary-clr);
}

.singlechat_v3__bot ol{
    padding-left: 1rem;
}

.singlechat_v3__bot ul{
    margin-bottom: 1rem;
}

.singlechat_v3__bot h1, 
.singlechat_v3__bot h2,
.singlechat_v3__bot h3{
    font-size: 1rem;
    margin-bottom: 1em;
}

.singlechat_v3__bot table{
    margin-bottom: 1em;
    font-size: 0.9rem;
    text-align: left;
}

.singlechat_v3__bot ol li{
    margin-bottom: 0.5em;
}

.singlechat_v3__bot strong{
    font-weight: 500;
}

.singlechat_v3__bot a{
    text-decoration: none;
    color: var(--v3-primary-clr);
    font-weight: 500;
}