.pagetab_v3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.3rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.5s;
    border: solid 1px transparent;
    box-sizing: border-box;
    text-wrap: nowrap;
    width: 100%;
    font-size: 0.9rem;
}

.pagetab_v3__content{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pagetab_v3:hover{
    background-color: var(--v3-light-clr);
    color: var(--v3-primary-clr);
    box-shadow: var(--box-shadow);
    border: var(--v3-light-border);
}

.pagetab_v3__active{
    background-color: var(--v3-light-clr);
    color: var(--v3-primary-clr);
    box-shadow: var(--box-shadow);
    border: var(--v3-light-border);
}