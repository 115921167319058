.newchat_v3 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex: auto;
    width: 55vw;
}

.newchat_v3__header {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10vh;
    gap: 1rem;
}

.newchat_v3__prompts{
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
}

/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 768px) {
    .newchat_v3{
        width: auto;
        padding: 0.5rem;
    }

    .newchat_v3__prompts{
        flex-direction: column;
    }
}