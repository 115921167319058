.v3_profile_tab{
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 0.25rem;
    border: solid 1px transparent;

}

.v3_profile_tab:hover{
    background-color: var(--v3-light-clr);
    box-shadow: var(--v3-box-shadow);
    border: var(--v3-light-border);
}

.v3_profile_tab_content{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.v3_profile_tab_name{
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    gap: 0.5rem;
    font-weight: 500;
    color: var(--v3-dark-clr);

}


.v3_profile_tab_package{
    font-size: var(--v3-small-font-size);
    color: var(--v3-primary-clr);
    font-weight: 400;
}
