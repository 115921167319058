/* src/ChatInput.css */
.tulsa_chat-input-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.10);
  border-radius: 2rem;
  
}

.tulsa_chat-input-container textarea {
  flex: 1;
  padding: 1.19rem;
  border: none;
  border-radius: 2rem;
  resize: none;
  overflow: hidden;
  scrollbar-width: thin;
  min-height: 3.4375rem;
  /* Maximum height for the textarea */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  color: #3E3E3E;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  /* 150% */
}

.tulsa_chat-input-container .address_input_tulsa {
  width: 100% !important;
  padding: 1.19rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  min-height: 3.4375rem;
  /* Maximum height for the textarea */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  color: #3E3E3E;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  /* 150% */
}



.tulsa_chat-input-container textarea:focus {
  outline: none;
  
}

.tulsa_send_button_area{
  padding: 0.4rem;
  
  height: calc(3.4375rem + 2px);
  width: calc(3.4375rem + 2px);
}

.tulsa_send_button {
  background-color: var(--primary-clr);
  border-radius: 50%;
  border: none;
  flex-shrink: 0;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.tulsa_send_button:hover{
  background-color: var(--primary-dark-clr);
}