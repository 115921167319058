.copilot_v3__sidebar{
    width: max(15%, 12rem);
    max-height: 100vh;
    background-color: var(--v3-light-gray-clr);
    padding: 0.5rem 0 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    transition: 0.5s;
}

.copilot_v3__sidebar_shrink{
    width: 3rem;
    max-height: 100vh;
    background-color: var(--v3-light-gray-clr);
    padding: 0.5rem 0 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    transition: 0.5s;
}



.copilot_v3__top{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v3-medium-gray-clr) var(--v3-light-gray-clr);
}
.copilot_v3__sidebar_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0.5rem;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: var(--v3-light-border);
}

.copilot_v3__header_arrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    color: var(--v3-gray-clr);
    cursor: pointer;
    background-color: var(--v3-light-clr);
    border-radius: 100%;
    transition: 0.5s;
}

.copilot_v3__header_arrow:hover{
    color: var(--v3-dark-clr);
    box-shadow: var(--v3-box-shadow);
}

.copilot_v3__free{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 1rem;
}

.copilot_v3__expandable_tab{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.copilot_v3__sub_tabs{
    border-left: var(--v3-light-border);
    margin-left: 1em;
    padding-left: 0.5em;
    padding-top: 0.5em;
    max-height: 20vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v3-medium-gray-clr) var(--v3-light-gray-clr);


}

.copilot_v3__sub_tabs_title{
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--v3-dark-clr);
    margin: 0.25rem 0 0.25rem 0;
}

.copilot_v3__bottom{
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}



/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 768px) {
    .copilot_v3__sidebar{
        box-shadow: var(--v3-box-shadow);
        position: fixed;
        top: 0;
        z-index: 999;
        height: 100%;
    }
}