.lan_home {
    overflow-x: hidden;
}

.lan_container {
    padding: 3rem;
}

.lan_secondary_button {
    display: flex;
    height: 3rem;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    border: 1px solid var(--primary-clr);
    color: var(--primary-clr);
    font-size: 1rem;
    font-weight: 600;
    transition: 0.5s;
    text-wrap: nowrap;
}

.lan_secondary_button:hover {
    background-color: var(--primary-dark-clr);
    color: white;
    cursor: pointer;
}

.lan_disabled_button {
    display: flex;
    width: 9.875rem;
    height: 3rem;
    padding: 1rem 2.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    color: rgb(68, 68, 68);
    background-color: rgb(88, 88, 88);
    font-size: 1rem;
    font-weight: 600;
    transition: 0.5s;
    text-wrap: nowrap;

}

.lan_primary_button {
    display: flex;
    width: 9.875rem;
    height: 3rem;
    padding: 1rem 2.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    color: white;
    background-color: var(--primary-clr);
    font-size: 1rem;
    font-weight: 600;
    transition: 0.5s;
    text-wrap: nowrap;

}

.lan_primary_button:hover {
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

.lan_home {
    font-family: 'poppins', sans-serif;
    width: 100%;
}

.land_home__hero {
    width: 100%;
    display: flex;
    min-height: 100vh;
}

.land_home__hero_new {
    display: flex;
    align-items: center;
    background-color: var(--primary-clr);
    padding: 3rem 2rem 0 2rem;
}

.land_home__hero_left_new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    color: white;
    padding: 0 3rem;
    margin-bottom: 3rem;
}

.land_home__hero_right_new {
    flex-shrink: 0;
    padding-top: 16px;
    align-self: flex-end;
}



.land_home__hero_left {
    background-color: var(--primary-clr);
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    color: white;
}

.lan_title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.875rem;
}

.land_home__hero_left .land_home__hero_right {
    flex-shrink: 0;
}

.land_home__hero_right img {
    object-fit: cover;

    min-height: 100vh;
}

.lan_btn_white {
    padding: 1rem 2.5rem;
    background-color: white;
    color: var(--dark, #3E3E3E);
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 3rem;
    transition: 0.5s;
    border-radius: 0.375rem;
}

.lan_btn_white:hover {
    background-color: var(--secondary-clr);
    cursor: pointer;
}

.lan_logo_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    padding: 1.4rem 0.5rem;
    height: 4rem;
    background-color: #E9FFF6;
    overflow-x: hidden;
    overflow-y: hidden;
}

.lan_logo_list_item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.iqland_motto {
    color: var(--primary-clr);
    font-size: 2.375rem;
    font-weight: 600;
}

.iqland_motto span {
    color: var(--secondary-clr);
}

.lan_home__motto_area {
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
    padding: 2rem 3rem;
}

.lan_home__motto_area_action {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.lan_btn_green {
    padding: 1rem 2.5rem;
    background-color: var(--primary-clr);
    color: white;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 3rem;
    transition: 0.5s;
    border-radius: 0.375rem;
}

.lan_btn_green:hover {
    cursor: pointer;
    background-color: var(--primary-dark-clr);
}

.lan_normal_text {
    color: #626262;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    max-width: 55rem;
}

.lan_free_trial_text {
    color: #3E3E3E;
    font-size: 1rem;
    font-weight: 600;
}

.lan_check_list_item {
    display: flex;
    gap: 0.5rem;
    color: #626262;
}

.iqland_motto__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.7rem;
    height: 100%;
}


.iqland_motto__left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.land_home__dashboard {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.land_home__copilot {
    display: flex;
    gap: 2rem;
    align-items: center;

}

.lan_home__why_iqland {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem 3rem;

}


.lan_sub_title_2 {
    color: #3E3E3E;
    text-align: center;
    font-size: 2.625rem;
    font-weight: 600;
}

.lan_sub_title_2 span {
    color: var(--primary-clr);
}

.lan_home__why_iqland_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;
}

.lan_home__why_iqland_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.lan_home__why_iqland_card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    min-width: 25rem;
    max-width: 35rem;
    border-radius: 1.125rem;
    border: 1px solid rgba(46, 46, 31, 0.12);
    background: rgba(0, 0, 0, 0.02);
    padding: 2rem 1.5rem;
}

.lan_home__why_iqland_card img {
    max-width: 4rem;
}

.lan_card__title {
    color: #3E3E3E;
    font-size: 1.375rem;
    font-weight: 600;
}

.lan_home__solution {
    background-color: var(--primary-clr);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 3rem 0 3rem;
    gap: 1rem;
}

.lan_home__solution__right {

    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 0 1rem;
    align-self: flex-end;
}


.lan_home__solution__left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 2rem;
}

.lan_btn_gold {

    padding: 1rem 2.5rem;
    background-color: var(--secondary-clr);
    color: #3E3E3E;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 3rem;
    transition: 0.5s;
    border-radius: 0.375rem;
}

.lan_btn_gold:hover {
    background-color: var(--secondary-dark-clr);
    cursor: pointer;
}

.lan_gold_subtitle {
    color: var(--secondary-clr);
    font-size: 1.5rem;
    font-weight: 600;
}

.lan_faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 3rem 2rem 3rem;
    gap: 2rem;
}

.lan_sub_title_acc {
    font-weight: bold !important;
    font-size: 1.2rem;
}

.lan_reviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
}

.lan_review_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-top: 2rem;
    justify-content: center;
}

.lan_solutions_section {
    padding: 4rem 0;
    background-color: var(--primary-clr);
}


/* Small devices (tablets) */
@media (max-width: 767.98px) {
    .lan_title {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .land_home__hero_new {
        padding: 2rem;
    }

    .land_home__hero_left_new {
        padding: 0;
        margin-bottom: 1rem;
    }

    .land_home__hero_right_new {
        display: none;
    }

    .land_home__dashboard {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .land_home__copilot {
        flex-wrap: wrap;
    }

    .lan_home__solution {
        padding: 2rem;
    }

    .lan_home__solution__right {
        display: none;
    }

    .lan_home__solution__left {
        margin-bottom: 1rem;
    }

    .lan_faqs {
        padding: 2rem;
    }

    .lan_home__motto_area {
        padding: 2rem;
    }

    .lan_home__why_iqland_cards {
        flex-direction: column;
    }

    .lan_home__why_iqland_card {
        width: 100%;
        max-width: 100%;
    }
    .lan_reviews {
        padding: 1rem;
    }

}