.chatscreen_v3{
    padding: 1.5rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v3-medium-gray-clr) var(--v3-light-clr);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chatscreen_v3__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--v3-light-border);
    margin-bottom: 1rem;
    color: var(--v3-gray-clr);
}
.chatscreen_v3__header_title{
    margin-bottom: 1rem;
    

}

.chatscreen_v3__header_title span{
    color: var(--v3-dark-clr);
    font-weight: 500;

}
.chatscreen_v3__header_delete{
    margin-bottom: 1rem;
    cursor: pointer;
    transition: 0.5s;

}

.chatscreen_v3__header_delete:hover{
    color: var(--v3-dark-clr);
}