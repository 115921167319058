.preprompt_v3{
    display: flex;
    align-items: center;
    gap: 1rem;
    /* background-color: var(--v3-light-gray-clr); */
    border-radius: 0.5rem;
    padding: 0.75rem;
    transition: 0.5s;
    cursor: pointer;
    width: 45%;
    flex: 1;
    flex-basis: auto;
    font-size: 0.9rem;
    font-weight: 400;
    background-color: #f8f8f875;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(128, 128, 128, 0.5);
}

.preprompt_v3:hover{
    background-color: var(--v3-medium-gray-clr);
}



@media screen and (max-width: 768px) {
  .preprompt_v3 {
    width: 100%;
    padding: 0.75rem;
  }
}

  