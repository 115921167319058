.package_v3{
    border-radius: 0.25rem;
    flex: 1;
    border: var(--v3-light-border);
    box-shadow: var(--v3-box-shadow);
    min-width: 20rem;
}


.package_v3__header{
    padding: 1rem;
}
.package_v3__title{
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--v3-primary-dark-clr);
}
.package_v3__desc{
    font-size: 1rem;
    color: var(--v3-gray-clr);
    min-height: 3rem;
}

.package_v3__price_period{
    padding: 1rem;
}
.package_v3__price{
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--v3-dark-clr);
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.package_v3__period{
    font-size: 1rem;
    color: var(--v3-gray-clr);
}

.package_v3__button_area{
    padding: 0 1rem;
}

.package_v3__button{
    padding: 0.5em 1rem;
    text-align: center;
    border-radius: 0.25rem;
    background-color: var(--v3-light-gray-clr);
    margin-bottom: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
}

.package_v3__button_clr{
    background-color: var(--v3-primary-clr);
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    color: var(--v3-light-clr);
}

.package_v3__button:hover{
    background-color: var(--v3-medium-gray-clr);
}

.package_v3__button_clr:hover{
    background-color: var(--v3-primary-dark-clr);
}

.package_v3__features{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    border-top: var(--v3-light-border);
}
.package_v3__feature{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
}
.package_v3__feature_icon{
    transform: scale(0.8);
    padding: 4px;
    border-radius: 100%;
    background-color: var(--v3-gray-clr);
    color: var(--v3-light-clr);
}
.package_v3__feature_text{

}