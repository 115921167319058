.copilot_new_chat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    width: 100%;
    height: 95%;
}

.copilot_new_chat__logo_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.copilot_new_chat__logo_area img {
    width: 11rem;
}

.copilot_new_chat__logo_area h3 {
    color: var(--primary-clr);
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.5rem;
}

.copilot_new_chat__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.copilot_new_chat__card {
    width: 22rem;
    height: 5rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: #f8f8f875;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    padding: 0.8rem;
    border: 1px solid rgba(128, 128, 128, 0.12);
}

.copilot_new_chat__card_disbled{
    width: 20rem;
    height: 4.5rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: lightgray;
    /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05); */
    padding: 0.8rem;
    border: 1px solid rgba(199, 199, 199, 0.849);
}


.copilot_new_chat__card:hover {
    cursor: pointer;
    background-color: #F5F5F5;
}

.copilot_new_chat__card_disbled :hover{
    cursor: default;
}

.copilot_new_chat__card h6 {
    color: #3E3E3E;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
    text-transform: capitalize;
    margin-bottom: 4px;
}

.copilot_new_chat__card div {
    color: #808080;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 300;
    line-height: 0.9375rem;
}

.copilot_new_chat__card_disbled h6 {
    color: #9c9c9c;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
    text-transform: capitalize;
    margin-bottom: 4px;
}

.copilot_new_chat__card_disbled div {
    color: #a7a7a7;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 300;
    line-height: 0.9375rem;
}

@media (max-width: 768px) {
    .copilot_new_chat{
        height: auto;
    }
}

@media (max-width: 600px) {
    .copilot_new_chat__cards {
        padding: 0 1rem;
        gap: 0.5rem;
    }

    .copilot_new_chat__card {
        width: 100%;
    }

    .copilot_new_chat__card_disbled{
        width: 100%;
    }

    .copilot_new_chat__logo_area h3 {
        margin-bottom: 0.5rem;
    }
    
}