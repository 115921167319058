.final_report{
    font-family: 'poppins', sans-serif;
    font-weight: 500;
}

.final_report .final_report_title{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.final_report table{
    width: 100%;
}

.final_report tr{
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: black;
    padding: 0 4px;
}

.app_form_sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app_form_sub_table_h td{
    background-color: gray;
    font-weight: bold;
    text-align: center;
    width: 100%;
    border-width: 0 1px 0 0;
}

.app_form_sub_table_item td{
    width: 100%;
    text-align: center;
}

.vertical-text {
    text-align: center; 
    height: 100px;
    text-wrap: wrap;
    background-color: black;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    width: 1rem;
    padding: 0 8px;
  }

  .sales_table_tr{
    width: 100%;
  }

  .sales_table_tr_head td{
    text-align: center;
  }

  .sales_table_tr td{
    border-width: 0 1px 0 0;
  }

  .sales_table_tr .disabled_td{
    background-color: gray;
  }


  .final_report_buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
    position: fixed;
    bottom: 0;
    background-color: white;
  }

  .final_report_next_prev{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2rem;
  }

  .report_page_break{
    height: 2.5rem;
    background-color: gray;
  }

  .final_report_page_num{
    font-size: 1rem;
    padding: 2rem;
    height: 3rem;
    text-align: center;
  }

  .final_report_share_download{
    
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .final_report_button{
    min-width: 5rem;
    padding: 1rem 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-clr);
    color: white;
    transition: 0.5s;
    border-radius: 0.25rem;
  }
  

  .final_report_button:hover{
    cursor: pointer;
    background-color: var(--primary-dark-clr);
  }

  .final_report_container{
    padding: 2rem;
  }

  .final_report_container2{
    border: 2rem solid gray;
  }

  .final_report_table_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    width: 100%;
    padding: 2rem;

  }

  .final_report_table_title{
    text-align: center;
    font-weight: 900;
    font-size: 1.4rem;
  }

  .content_item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    max-width: 768px;
  }