.google_address{
    display: flex;
    align-items: center;
}
.google_address_input {
    width: 100%;
    min-width: 20rem;
    height: 40px;
    color: var(--text-clr);
    border-style: solid;
    border-color: rgb(168, 168, 168);
    border-radius: 0.5rem;
    border: 1px solid #DFDFDF;
    padding-left: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0);
    flex-shrink: 0;
    flex-grow: 1;
    flex: 2;
}

.google_address_input:focus {
    outline: none;
    border: 1px solid #29781D;
}

.google_address .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    top: -6px !important;
}

.google_address .css-p0rm37 {
    top: -6px !important;
}