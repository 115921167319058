.custom-nav-bg{
    background: white;
    font-family: 'poppins';
}

.container{
    max-width: 100% !important;
    margin: auto 2.5rem !important;
}


.dropdown-menu {
    --bs-dropdown-link-active-bg:#2a7a1b !important;
}

.dropdown-item:hover{
    background: #8ad17d !important;
}

.navbar-nav button{
    background: #2a7a1b !important;
    font-size: 0.8rem;
    padding: 0.5rem 2rem;
}

.navbar-nav button:hover{
    background: #174d0e !important;
}

.link{
    color: black;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .navbar-nav button{
        margin-top: 0.5rem;
        width: 100%;
    }

    .container{
        margin: auto !important;
    }


}


  
  /* Small devices (tablets) */
  @media (max-width: 767.98px) {
    .navbar-nav button{
        margin-top: 0.5rem;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .container{
        margin: auto !important;
    }

    .navbar-nav .nav-buttons{
        gap: 0.5rem;
    }
  }

  

  
  