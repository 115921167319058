.button_on_parcel{
    position: absolute;
    right: 0;
    top: -3rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    color: white;
    transition: 0.5s;
    user-select: none;
}

.button_on_parcel_active{
    background-color: var(--primary-clr);
    cursor: pointer;
}

.button_on_parcel_active:hover{
    background-color: var(--primary-dark-clr);
}

.button_on_parcel_disabled{
    cursor: not-allowed;
    background-color: gray;
    color: rgb(177, 177, 177);
    
}
