.lan_architect__ex_marketplace {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 4rem;
    gap: 2rem;
    background: #F2FFFA;
}

.lan_architect__cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    text-align: center;
}

.lan_architect__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 0.5rem;
    min-width: 20rem;
    max-width: 30rem;
    padding: 1.9375rem 1.75rem 1.8125rem 1.75rem;
    border-radius: 0.875rem;
    border: 1px solid rgba(18, 99, 66, 0.12);
    background: rgba(0, 0, 0, 0.02);
}

.lan_architect__why_iqland_card{
    background-color: var(--primary-clr) !important;
    color: white !important;
}

.lan_architect__why_iqland_card .lan_card__title{
    color: white !important;
}

.lan_architect__why_iqland_card .lan_normal_text{
    color: white !important;
}
.lan_partner_iq{
    display: flex;
    background-color: var(--primary-clr);
}

.lan_partner_iq__left{
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    flex: 1;
    flex-shrink: 0;
    min-width: 50%;
}

.lan_partner_item{
    display: flex;
    gap: 1.5rem;
}

.lan_partner_item img{
    width: 2.375rem;
    height: 2.375rem;
}

.lan_contact_us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: #F2FFFA;
    text-align: center;
}

.lan_partner_iq__right{
    flex-shrink: 1;
    width: 50%;
    object-fit: cover;
}

@media (max-width: 767.98px) {
    .lan_partner_iq__left{
        padding: 2rem;
    }

    .lan_partner_iq__right{
        display: none;
    }
    
    .lan_architect__ex_marketplace{
        padding: 2rem;
    }

    .lan_architect__why_iqland_card{
        align-items: center;
        text-align: center;
    }

    .lan_container{
        padding: 2rem;
    }

    .lan_architect__cards{
        flex-direction: column;
    }
    .lan_architect__card{
        min-width: 100%;
    }
}