.copilot_v3{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: var(--v3-light-gray-clr);
    overflow: hidden;

}

.copilot_v3__main{
    flex: auto;
    padding: 1rem;
    width: calc(100% - max(12rem, 15%));
    
}

.copilot_v3__container{
    width: 100%;
    height: 100%;
    background-color: var(--v3-light-clr);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.copilot_v3__chatbar_in_chat{
    position: absolute;
    bottom: 2.5rem;
    width: 80%;
    box-shadow: var(--v3-box-shadow);
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
}


@media screen and (max-width: 768px) {
    .copilot_v3__main{
        padding: 0;
    }

    .copilot_v3__chatbar_in_chat{
        bottom: 0.5rem;
        width: 90%;
    }
}