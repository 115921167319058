.followup_v3{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: var(--v3-light-border);
    transition: 0.5s;
    cursor: pointer;
}

.followup_v3:hover{
    color: var(--v3-primary-clr);
}

.followup_v3__text{
    font-weight: 500;
}