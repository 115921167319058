.pricing_v3{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v3-medium-gray-clr) var(--v3-light-clr);
    padding: 1rem;

}
.pricing_v3__header{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}


.pricing_v3__title{
    font-size: 2rem;
    font-weight: 600;
    color: var(--v3-dark-clr);
}
.pricing_v3__desc{
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--v3-dark-clr);
    margin-top: 2rem;
}
.pricing_v3__packages{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: var(--v3-light-border);
}