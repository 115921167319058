.user_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4.125rem;
    padding: 0 1.75rem;
    border-style: solid;
    border-color: #82828229;
    background-color: #fff;
    border-width: 0 0 1px 0;
    font-family: 'poppins', sans-serif;
    position: fixed;
    width: 100%;
    z-index: 9999999;
}

.user_nav__logo_top:hover {
    cursor: pointer;
}

.user_nav__tabs {
    display: flex;
    list-style-type: none;
    gap: 3em;
    margin-bottom: 0;
    padding-left: 0;
}

.user_nav__tabs li {
    padding-left: 0;
    font-size: 1rem;
    font-weight: 500 !important;
    padding-bottom: 1rem;
    transition: 0.5s;
    border-style: solid;
    border-color: white;
    border-width: 0 0 0.15rem 0;
    margin-bottom: -1rem;
}

.user_nav__tabs li:hover {
    cursor: pointer;
    color: var(--primary-clr);
    border-style: solid;
    border-color: var(--primary-clr);
    border-width: 0 0 0.15rem 0;

}

.user_nav__active_tab {
    color: var(--primary-clr);
    border-color: var(--primary-clr) !important;
}

.user_nav_profile_area {
    position: relative;
}

.user_nav_profile {
    display: flex;
    align-items: center;
    gap: 0.81rem;

}

.user_nav .user_nav_profile img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
}

.user_nav__burger_icon {
    display: none;
}

/* Dropdown wrapper */
.user_nav__dropdown {
    position: relative;
}

/* Sub-tabs (initially hidden) */
.user_nav__sub_tabs {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    list-style: none;
    padding: 8px 4px;
    margin: 0;
    z-index: 10;
    width: 150px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: none;
}

.user_nav__sub_tabs .user_nav__active_tab {
    color: var(--primary-clr);
    border-style: none !important;
}

/* Show on hover */
.user_nav__dropdown:hover .user_nav__sub_tabs {
    display: block;
}

/* Styling for sub-tabs */
.user_nav__sub_tabs li {
    padding: 10px 15px;
    cursor: pointer;
    white-space: nowrap;
    height: 2.5rem;
    margin-bottom: 0;
}

.user_nav__sub_tabs .user_nav__sub_tab {
    color: var(--text-clr);
}

.user_nav__sub_tabs .user_nav__sub_tab:hover{
    border-style: none !important;
}

.user_nav__sub_tabs li:hover {
    background-color: #f0f0f0;
}



@media (max-width: 768px) {

    .user_nav {
        padding: 0 1rem;
    }

    .user_nav_profile {
        gap: 4px;
    }

    .user_nav__tabs {
        display: none;
    }

    .user_nav__burger_icon {
        display: block;
    }

    .user_nav__burger_icon:hover {
        cursor: pointer;
    }
}