.bottomnav_v3{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: var(--v3-small-font-size);
    color: var(--v3-gray-clr);
    margin-bottom: 0.5rem;
}

.bottomnav_v3__item{
    cursor: pointer;
    transition: 0.5s;
}

.bottomnav_v3__item:hover{
    color: var(--v3-dark-clr);
}

@media screen and (max-width: 768px) {
    .bottomnav_v3{
        display: none;
    }
}
