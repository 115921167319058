.msgtab_v3{
    color: var(--v3-gray-clr);
    display: flex;
    align-items: center;
    padding: 0.25rem;
    transition: 0.5s;
    cursor: pointer;
    border: solid 1px transparent;
    border-radius: 0.25rem;
    text-wrap: nowrap;
    height: 2.5em;
    font-size: var(--v3-small-font-size);
    
}

.msgtab_v3:hover{
    background-color: var(--v3-light-clr);
    color: var(--v3-primary-clr);
    box-shadow: var(--box-shadow);
    border: var(--v3-light-border);
}

.msgtab_v3__active{
    background-color: var(--v3-light-clr);
    color: var(--v3-primary-clr);
    box-shadow: var(--box-shadow);
    border: var(--v3-light-border);
}