.user_input_msg_popup{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.user_input_msg_container{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    border-radius: 0.5rem;
}
/* .user_input_msg_text{

} */
.user_input_msg_buttons{
    display: flex;
    gap: 1rem;
}
.user_input_msg_button{
    width: 8rem;
    padding: 1rem 2rem;
    background-color: var(--primary-clr);
    border-radius: 0.5rem;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.user_input_msg_button:hover{
    background-color: var(--primary-dark-clr);
}