.chatbar_v3{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--v3-medium-gray-clr);
    border-radius: 0.25rem;
    background-color: var(--v3-light-clr);
    position: relative;
}

.chatbar_v3__google_address{
    position: absolute;
    top: -50%;
    width: 100%;
    margin-left: -0.5rem;
    border-radius: 0.25rem;
}

.chatbar_v3__google_address .input_google_address{
    border-radius: 0.25rem !important;
}

.chatbar_v3__close{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: 100%;
    background-color: var(--v3-light-clr);
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.chatbar_v3__attachment{
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    transition: 0.5s;
    background-color: var(--v3-light-clr);
    width: 90%;
}

.chabar_v3__attachment_icon{
    transition: 0.5s !important;
}

.chabar_v3__attachment_icon:hover{
    transform: rotate(90deg);
    cursor: pointer;
    color: var(--v3-primary-clr);
}


.chatbar_v3__close:hover{
    cursor: pointer;
    background-color: var(--v3-light-gray-clr);
}

.chatbar_v3__send{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--v3-dark-clr);
    border-radius: 0.25rem;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.5s;
    align-self: flex-end;
}

.chatbar_v3__send:hover{
    background-color: var(--v3-darker-clr);
}

.chatbar_v3__send__icon{
    color: var(--v3-light-clr);
}

.chatbar_v3__textarea{
    width: 100%;
    min-height: 2.25rem;
    outline: none;
    border: none;
    resize: none;
    line-height: 2.25rem;
    padding-bottom: 1rem;
    scrollbar-width: thin;
    scrollbar-color: var(--v3-medium-gray-clr) var(--v3-light-clr);
}


@media screen and (max-width: 768px) {
    
}