.v3_profile_img{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: 0.5s;


}

.v3_profile_img img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    transition: 0.5s;
}