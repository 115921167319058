.solution_slide_show{
    position: relative;
    cursor: pointer;
}
.solution_slides{
    transition: transform 0.5s ease-in-out;
    display: flex;
    width: 300%;
}
.solution_slide{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
}
.solution_texts{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 6rem;
}
.solution_title{
    font-weight: 700;
    color: white;
    font-size: 3rem;
}
.solution_sub_title{
    font-weight: 500;
    font-size: 2rem;
    color: var(--secondary-clr);
}
.solution_para{
    color: white;
    font-weight: 300;
    margin-bottom: 1rem;
}

.solution_image{
    min-width: 25rem;
    padding-right: 6rem;
}

.solution_slide_show_controls{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

.solution_slide_show_arrow{
    color: var(--secondary-clr);
    width: 5rem !important;
    height: 5rem !important;
    cursor: pointer;
}

.solution_slide_show_arrow:hover{
    color: var(--secondary-dark-clr);
}

@media (max-width:768px){
    .solution_image{
        display: none;
    }

    .solution_texts{
        padding: 0 4.5rem;
    }
}