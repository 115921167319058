.lan_review_card {
    display: flex;
    padding: 1.9205rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.9205rem;
    flex: 1 0 0;
    min-width: 20rem;
    max-width: 30rem;
    border-radius: 0.75rem;
    background: #F7F5FF;
}

.lan_review_card__profile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.lan_review_card__profile img {
    border-radius: 50%;
}

.lan_review_card__profile_name {
    color:#211F25;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.0125rem;
}

.lan_review_card__profile_occ {
    color:#3D3A41;
    font-size: 0.96025rem;
    font-weight: 400;
}

@media (width:768px){
    .lan_review_card{
        width: 100%;
    }
}