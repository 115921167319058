.unlockpro_v3{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    margin-top: 1rem;
}

.unlockpro_v3__header{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--v3-secondary-clr);

}
.unlockpro_v3_title{
    color: var(--v3-primary-clr);
}
.unlockpro_v3__content{

    font-size: 0.7rem;
    color: var(--v3-gray-clr);

}

.unlockpro_v3__button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    color: var(--v3-primary-clr);
    background-color: var(--v3-light-clr);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.5s;
    font-size: 0.8rem;
    width: 70%;
    text-wrap: nowrap;
}

.unlockpro_v3__button:hover{
    background-color: var(--v3-medium-gray-clr);
    box-shadow: var(--v3-box-shadow);
}